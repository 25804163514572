<template>
  <b-container>
    <b-row>
      <b-col v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>{{message}}</b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <h1>{{ source.source }}</h1>
        <div>publisher: {{ source.publisher }}</div>
        <div>type: {{ source.type }}</div>
        <div>country: {{ source.countryName }}</div>
        <div>country code: {{ source.countryCode }}</div>
        <div>language: {{ source.langName }}</div>
        <div>language code: {{ source.langCode }}</div>
        <div>
          link: <a :href="source.url" target="_blank">{{ source.url }}</a>
        </div>
        <div>parser: {{ source.parser }}</div>
        <div class="my-4">
          <b-btn class="mr-2" variant="outline-primary" @click="showLogs">show logs</b-btn>
          <b-btn class="mr-2" variant="outline-primary" @click="testFeed">test feed</b-btn>
          <b-button class="mr-2" v-if="permissions.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: source.id, model: 'source' }}">Edit</b-button>
          <b-button v-if="permissions.delete"  v-b-toggle.collapse-1 variant="outline-danger">delete</b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
                <b-btn variant="outline-danger" @click="deleteSource">confirm delete</b-btn>
            </b-card>
          </b-collapse>
        </div>
      </b-col>
      <b-col>
        <h3>Most recent</h3>
        <div>publication: {{ moment(source.lastPublication).format('YYYY-MM-DD') }}</div>
        <div>success: {{ moment(source.lastSuccess).format('YYYY-MM-DD') }}</div>
        <div>error: {{ moment(source.lastError).format('YYYY-MM-DD') }}</div>
        <h3 class="mt-3">Technical fields</h3>
        <div>baselined: {{ source.baselined }}</div>
        <div>active: {{ source.active }}</div>
      </b-col>
    </b-row>
    <b-row v-if="show.logs">
      <b-col>
        <b-card class="mb-3" v-for="log in logs" :key="'logid-' + log.id">
          <div v-if="log.error" class="text-white bg-danger"><strong>ERROR</strong></div>
          <div v-else ><strong>success</strong></div>
          <div>{{log.message}}</div>
          <div>{{log.created_at}}</div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="show.news">
      <b-col>
        <div v-if="news.length==0">
          <b-alert show variant="danger">Error - feed is not working</b-alert>
        </div>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <h4 class="card-title">
            <a :href="item.link" target="_blank">{{item.name}}</a>
            <b-btn v-if="permissions.save" class="mr-2" variant="outline-primary" @click="save(item)">save</b-btn>
          </h4>
          <div class="mb-2">{{item.source}} - {{item.dateTime}}</div>
          <div class="mb-2">{{item.body}}</div>
          <div class="mb-2">{{item.publisher}} - {{item.type}}</div>
          <div class="mb-2">{{item.year}} - {{item.quarter}} - {{item.month}} - {{item.week}} </div>
          <div class="mb-2">{{item.link}}</div>
          <div class="mb-2">{{item.dateTime}}</div>
          <div class="mb-2">{{item.countryName}} - {{item.countryCode}} - {{item.langName}} - {{item.langCode}}</div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import moment from 'moment'

export default {
  props: ['permissionDelete', 'permissionSave'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'source', action: 'open source', model: 'source', model_id: this.$route.params.id })
    this.permissions.delete = ac.can(this.user.acgroups).deleteAny('source').granted
    this.permissions.edit = ac.can(this.user.acgroups).updateAny('source').granted
    this.permissions.save = ac.can(this.user.acgroups).createAny('publication').granted
    this.load()
  },
  data () {
    return {
      feedTestDate: null,
      loading: true,
      logs: [],
      message: '',
      moment: moment,
      news: [],
      permissions: {
        delete: false,
        edit: false,
        save: false
      },
      show: {
        logs: false,
        news: false
      },
      source: {}
    }
  },
  methods: {
    clearShow: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    deleteSource: async function () {
      this.$logger.debug('started delete')
      try {
        let apiName = 'cosmos'
        let path = `/source/${this.source.id}`
        let response = await this.$Amplify.API.del(apiName, path)
        this.$logger.debug('response', response)
        this.loading = true
        this.clearShow()
        this.show.table = true
        this.getSources()
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    load: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      try {
        [this.source] = await this.$Amplify.API.get('cosmos', `/source/${this.$route.params.id}/adminview`)
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    save: async function (item) {
      try {
        let apiName = 'cosmos'
        let path = `/news/publication`
        let params = { body: item }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.$bvModal.msgBoxOk('News item saved!')
      } catch (e) {
        this.$bvModal.msgBoxOk('Saving failed!')
        this.$logger.warn('event saving error: ' + e)
      }
    },
    showLogs: async function (source, index, event) {
      try {
        let apiName = 'cosmos'
        let path = `/source/${this.source.id}/scrapelog`
        let response = await this.$Amplify.API.get(apiName, path)
        this.logs = response
        this.clearShow()
        this.show.logs = true
        console.log(this.logs)
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    testFeed: async function () {
      this.$logger.debug('testFeed started')
      try {
        let apiName = 'cosmos'
        let path = '/rss/test'
        let params = { body: { source: this.source } }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.news = response
        this.clearShow()
        this.show.news = true
      } catch (e) {
        this.$logger.warn('testRSS error' + e)
      }
    }
  }
}
</script>

<style></style>
